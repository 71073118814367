jQuery(window).load(function() {
    document.cookie = "MCEvilPopupClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = "MCPopupClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
});

jQuery(document).ready(function() {

    // Display overlay on click - Header Button
    jQuery('#pre-header-subscribe').click(function() {
        jQuery('#newsletter-signup-overlay').show();
    });

    jQuery('#mailchimp-subscribe').click(function() {
        jQuery('#newsletter-signup-overlay').show();
    });

    /* 
    Display overlay on click - CTA on particular pages
        - Annual Report Financials
        - Policy Advocacy
        - Reports Publications
    */
    jQuery('#cta-subscribe-button').click(function() {
        jQuery('#newsletter-signup-overlay').show();
    });

    // Close overlay
    jQuery('#mc-overlay-close').click(function() {
        jQuery('#newsletter-signup-overlay').fadeOut();
    });
});